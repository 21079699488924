<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
// import { refreshToken } from "@/api/login.js";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.userInfo,
    // }),
  },
  mounted() { 
    // if (this.userInfo && this.userInfo.user_id !== '0') {
    //   this.refreshToken(this.userInfo.refresh_token);
    // } else {
    //   this.$router.push("/login");
    // }
  },
  methods: {
    // ...mapActions(["setUserInfo","logout"]),
    // refreshToken(refresh_token) {
    //   refreshToken(refresh_token)
    //   .then(userInfo => {
    //     this.setUserInfo(userInfo)
    //   }).catch(err=>{
    //     console.log(err)
    //     this.$router.push("/login");
    //   })
    // },
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
}
</style>
