import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: () => import( '@/page/home/index'),
      meta:{
        title: '青原掌上大厅'
      }
    },
    {
      path: '/appoint',
      name: 'appoint',
      component: () => import( '@/page/appoint/index'),
      meta:{
        title: '预约办理'
      }
    },
    {
      path: '/my-appoint',
      name: 'my-appoint',
      component: () => import( '@/page/myAppoint/index'),
      meta:{
        title: '我的预约'
      }
    },
    {
      path: '/query',
      name: 'query',
      component: () => import( '@/page/query/index'),
      meta:{
        title: '出件查询'
      }
    },
    {
      path: '/assets',
      name: 'assets',
      component: () => import( '@/page/assets/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: () => import( '@/page/qrcode/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import( '@/page/apply/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/notify',
      name: 'notify',
      component: () => import( '@/page/notify/index'),
      meta:{
        title: '消息通知'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import( '@/page/login/index'),
      meta:{
        title: '授权登录'
      }
    },
    {
      path: '/xcxlogin',
      name: 'xcxlogin',
      component: () => import( '@/page/xcxlogin/index'),
      meta:{
        title: '授权登录'
      }
    },
    {
      path: '/scan',
      name: 'scan',
      component: () => import( '@/page/scan/index'),
      meta:{
        title: '扫码结果'
      }
    },
    {
      path: '/violation',
      name: 'violation',
      component: () => import( '@/page/violation/index'),
      meta:{
        title: '违规查询'
      }
    },
    {
      path: '/carryOn',
      name: 'carryOn',
      component: () => import( '@/page/carryOn/index'),
      meta:{
        title: '青原区一体化办公'
      }
    },
    {
      path: '/checkNum',
      name: 'checkNum',
      component: () => import( '@/page/checkNum/index'),
      meta:{
        title: '青原区一体化办公'
      }
    },
    {
      path: '/carryOnCode',
      name: 'carryOnCode',
      component: () => import( '@/page/carryOnCode/index'),
      meta:{
        title: '青原区一体化办公'
      }
    },
    {
      path: '/orderInstructions',
      name: 'orderInstructions',
      component: () => import( '@/page/orderInstructions/index'),
      meta:{
        title: '预约取号'
      }
    },
    {
      path: '/orderMatter',
      name: 'orderMatter',
      component: () => import( '@/page/orderMatter/index'),
      meta:{
        title: '预约取号'
      }
    },
    {
      path: '/orderSuccess',
      name: 'orderSuccess',
      component: () => import( '@/page/orderSuccess/index'),
      meta:{
        title: '预约取号'
      }
    },
    {
      path: '/orderRecords',
      name: 'orderRecords',
      component: () => import( '@/page/orderRecords/index'),
      meta:{
        title: '预约取号'
      }
    },
    {
      path: '/orderCode',
      name: 'orderCode',
      component: () => import( '@/page/orderCode/index'),
      meta:{
        title: '预约取号'
      }
    },
    {
      path: '*',
      redirect:'/'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})