import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import 'amfe-flexible';
import 'style/reset.css'
import { getQueryVariable } from './common/utils';

// 使用 VConsole
import VConsole from 'vconsole';
if (getQueryVariable('state') === 'debug') {
  new VConsole({ theme: 'dark' });
  console.log(navigator.userAgent) // ua
}
Vue.config.productionTip = false

// store.commit('setUserInfo',{
//   // access_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwMTkwMDUiLCJkZXB0X2NvZGUiOiIwTVIiLCJ1c2VyX25hbWUiOiIxODg3MDM0OTY2OCIsInRva2VuX3R5cGUiOiJhY2Nlc3NfdG9rZW4iLCJyb2xlX25hbWUiOiJhZG1pbixtYW5hZ2VyIiwicG9zdF9pZCI6IiIsInVzZXJfaWQiOiIxNzAxMDU4NTYxNTk4MzI0NzM4Iiwicm9sZV9pZCI6IjE1NzQ5NTc2MjA1NTA3NTg0MDIsMTU3NDk1NzYyMDk5OTU0ODkzMCIsInBob25lIjoiMTg4NzAzNDk2NjgiLCJuaWNrX25hbWUiOiJDYXJwZSAgZGllbSIsIm9hdXRoX2lkIjoiMTcwMTA1ODQ2MjY5NjYzNjQxNyIsImRlcHRfaWQiOiIxNTk3MTM2NjAyNjQ2ODE4ODE4IiwidGhpcmRfbG9naW5faWQiOiIxNzAxMDU4NDYyNjk2NjM2NDE3IiwiYWNjb3VudCI6IjE4ODcwMzQ5NjY4IiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE3MzE5MTQzNDQsIm5iZiI6MTczMTU1NDM0NH0.i89zdiLKQ7gclJdkMLNJv3BsRh4e7eP9qYWehKjr5luFKvUOXRRr_lq20_-1l7ar8Uof5RBIXLjK4jJ5LeU8Xw",
//   access_token:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwMDAwMDAiLCJkZXB0X2NvZGUiOiIzMyIsInVzZXJfbmFtZSI6IlF5cXp3ZHQiLCJ0b2tlbl90eXBlIjoiYWNjZXNzX3Rva2VuIiwicm9sZV9uYW1lIjoiYWRtaW5pc3RyYXRvciIsInBvc3RfaWQiOiIxMTIzNTk4ODE3NzM4Njc1MjAxIiwidXNlcl9pZCI6IjExMjM1OTg4MjE3Mzg2NzUyMDEiLCJyb2xlX2lkIjoiMTEyMzU5ODgxNjczODY3NTIwMSIsInBob25lIjoiIiwibmlja19uYW1lIjoiYWRtaW4iLCJvYXV0aF9pZCI6IjAiLCJkZXB0X2lkIjoiMTU2NzAzODczNjc3ODY3ODI3NCIsInRoaXJkX2xvZ2luX2lkIjoiMCIsImFjY291bnQiOiJReXF6d2R0IiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE3MzE5MTM3ODAsIm5iZiI6MTczMTU1Mzc4MH0.Ittp4VENdbaCoMHgoj0YJ1LTbHDDa32kou_0roxhDsbteYQ75sTyaswxk0Fbq-r7l1xDS4j3gKOJGzMTxnOVwQ',
//   account: "18870349668",
//   avatar: "https://thirdwx.qlogo.cn/mmopen/vi_32/6G0gAzJibFMNy4YDuY1RaznveOhbrYiaNiaoicFdH2qTDUibxQe32NCdQW9ptxl9hxHAPYvdWUhxSZVPicV9AuFfic10A/132",
//   dept_code: "",
//   dept_id: "1597136602646818818",
//   expires_in: 360000,
//   license: "powered by 智慧科技",
//   nick_name: "Carpe  diem",
//   oauth_id: "1701058462696636417",
//   phone: "18870349668",
//   refresh_token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ1c2VyX2lkIjoiMTcwMTA1ODU2MTU5ODMyNDczOCIsIm9hdXRoX2lkIjoiMTcwMTA1ODQ2MjY5NjYzNjQxNyIsInRva2VuX3R5cGUiOiJyZWZyZXNoX3Rva2VuIiwiY2xpZW50X2lkIjoic3dvcmQiLCJleHAiOjE3MzY4MTkzNTUsIm5iZiI6MTczMDc3MTM1NX0.sf8ePAeLPztGB8JoJNia49Dgk3DNqCQumBbdduAMb-ZMPajs-N2PLQ6YE0ZItyThu_LLjxr8Xgzp9Inv12KdzQ",
//   role_name: "admin,manager",
//   tenant_id: "019005",
//   third_login_id: "1701058462696636417",
//   token_type: "bearer",
//   user_id: "1701058561598324738",
//   user_name: "18870349668",
// })

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const userInfo = store.state.userInfo
  // 环境校验
  if (window.__wxjs_environment === 'miniprogram') {
    if (!userInfo && to.path !== '/xcxlogin') {
      next('/xcxlogin');
    } else if(from.path==='/orderSuccess'&&to.path==='/orderMatter'){
      next('/index');
    } else {
      next()
    }
  } else {
    if (!userInfo && to.path !== '/login') {
      next('/login');
    } else if(from.path==='/orderSuccess'&&to.path==='/orderMatter'){
      next('/index');
    } else {
      next()
    }
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')